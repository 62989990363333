<template>
  <div>
    <a-form-model
      class="mb-1"
      ref="addRoleForm"
      :model="form"
      :rules="rules"
      @submit="handleSubmit"
    >
      <a-row>
        <a-col :span="24">
          <a-form-model-item ref="name" prop="name" label="Role name">
            <a-input v-model="form.name"
                     placeholder="Role name"
            >
            </a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="mt-0">
        <p class="font-size-16"><a-icon type="cluster" /> Select permissions:</p>
        <a-form-model-item prop="permissions" style="clear:both;">
          <a-checkbox-group v-model="form.permissions"  name="permissions" @change="permChange">
            <div class="m-0" v-for="cat in permCategories" :key="cat">
              <div>
                <p class="font-weight-bold mt-3 mb-1"><a-icon type="deployment-unit" /> {{ cat }}</p>
                <a-checkbox v-for="perm in getPermissionTypesByName(cat)"
                            :key="perm.id"
                            :value="perm.id"
                            name="permissions"
                            @change="permChange"
                            class="ml-4 p-0"
                >
                  <span v-if="perm.id === 1">{{ perm.name }}</span>
                  <span v-else>
                    <a-icon v-if="perm.name.split('.')[1]==='view'" type="eye" />
                    <a-icon v-if="perm.name.split('.')[1]==='create'" type="plus" />
                    <a-icon v-if="perm.name.split('.')[1]==='edit'" type="edit" />
                    <a-icon v-if="perm.name.split('.')[1]==='delete'" type="delete" />
                    {{ perm.name.split('.')[1] }}
                  </span>
                </a-checkbox>
              </div>
            </div>
          </a-checkbox-group>
        </a-form-model-item>
      </div>
      <hr>
      <div class="text-right">
        <!-- <a-button class="float-left" @click="closeAddRoleModal">
          Cancel
        </a-button> -->
        <CustomButton class="float-left"  @click="closeAddRoleModal">
          Cancel
        </CustomButton>
        <a-button type="primary" @click="handleSubmit" :loading="loading">
          Create
        </a-button>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import store from 'store'
import CustomButton from '@/components/custom/button/CustomButton'

export default {
  name: 'addRole.vue',
  mixins: [accessMix],
  components: {
    CustomButton,
  },
  computed: {
    loading() {
      return this.processing
    },
  },
  data() {
    return {
      processing: false,
      roles_list: store.get('app.users.roles'),
      permissions_list: store.get('app.users.permissions'),
      permCategories: this.getPermissionsCategories(),
      form: {
        permissions: [],
        name: '',
      },
      rules: {
        name: [{ required: true, message: 'Please input role name', trigger: 'change' }],
        permissions: [{ required: true, message: 'Please select permissions', trigger: 'change' }],
      },
    }
  },
  mounted () {
    // ---
    // console.log(this.getPermissionTypesByName('banners'))
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.$refs.addRoleForm.validate(valid => {
        if (valid) {
          this.processing = true
          this.createRole()
        } else {
          return false
        }
      })
    },
    closeAddRoleModal() {
      this.$emit('closeModalFunction')
    },
    permChange(e) {
      console.log(this.form.permissions)
    },
    createRole() {
      const url = 'admin/roles'
      return apiClient.post(url, this.form).then((response) => {
        this.roles_list.push(response.data.data)
        store.set('app.users.roles', this.roles_list)
        this.$notification.success({
          message: 'Role "' + response.data.data.name + '" successfully created',
          description: '',
        })
        this.processing = false
        this.$emit('submitModalFunction', response.data.data)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while creating new role',
          description: error.message,
        })
        this.processing = false
      })
    },
  },
}
</script>

<style scoped>

</style>
