<template>
  <div class="rp-content">
    <div class="roles-content">
      <a-row>
        <a-col :span="18">
          <h3>Roles</h3>
        </a-col>
        <a-col :span="6" class="text-right">
          <a-button type="primary" size="large" @click="showAddRoleModal">
            <a-icon type="plus" /> Create Role
          </a-button>
        </a-col>
      </a-row>
      <a-card class="mt-2">
        <a-table :columns="roleColumns" :data-source="rolesData">
          <template
            v-for="col in ['name']"
            :slot="col"
            slot-scope="text, record"
          >
            <div :key="col">
              <a-input
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                @change="e => handleChange(e.target.value, record.id, col)"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
          <template slot="permissions" slot-scope="text, record">
            <div :key="col">
              <a-tag v-for="perm  in record.permissions" :key="perm.id" class="m-1">
                <b v-if="perm.id===1">
                  <a-icon type="thunderbolt" />
                  {{ perm.name }}
                </b>
                <div v-else>
                  <a-icon v-if="perm.name.split('.')[1]==='view'" type="eye" />
                  <a-icon v-if="perm.name.split('.')[1]==='create'" type="plus" />
                  <a-icon v-if="perm.name.split('.')[1]==='edit'" type="edit" />
                  <a-icon v-if="perm.name.split('.')[1]==='delete'" type="delete" />
                  {{ perm.name }}
                </div>
              </a-tag>
            </div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="editable-row-operations">
              <span>
                <a href="javascript:;" @click="() => showEditRoleModal(record)"><a-icon type="edit" /> Edit</a>
              </span>
              <span>
              <a-popconfirm
                v-if="rolesData.length"
                title="Sure to delete?"
                @confirm="() => deleteRole(record.id)"
              >
              <a href="javascript:;"
                  :disabled="record.id === 1"
                  v-add-class-hover="'text-danger'"
                >
                  <a-icon type="delete" /> Delete
              </a>
              </a-popconfirm>
            </span>
            </div>
          </template>
        </a-table>
      </a-card>
    </div>
    <div class="permissions-content mt-5">
      <a-row>
        <a-col :span="18">
          <h3>Permissions</h3>
        </a-col>
        <a-col :span="6" class="text-right">
          <a-button type="primary" size="large" @click="showAddPermModal">
            <a-icon type="plus" /> Create Permission
          </a-button>
        </a-col>
      </a-row>
      <a-card class="mt-2">
        <a-table :columns="permColumns" :data-source="permData">
          <template
            v-for="col in ['name']"
            :slot="col"
            slot-scope="text, record"
          >
            <div :key="col">
              <a-input
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                @change="e => handleChange(e.target.value, record.id, col)"
              />
              <template v-else>
                <a-icon v-if="record.id === 1" type="thunderbolt" />
                <a-icon v-if="text.split('.')[1] === 'view'" type="eye" />
                <a-icon v-if="text.split('.')[1] === 'create'" type="plus" />
                <a-icon v-if="text.split('.')[1] === 'edit'" type="edit" />
                <a-icon v-if="text.split('.')[1] === 'delete'" type="delete" />
                {{ text }}
              </template>
            </div>
          </template>
          <template slot="roles" slot-scope="text, record">
            <div :key="col" class="m-1">
              <a-tag v-for="role in record.roles" :key="role.id" >
                  <a-icon v-if="role.id === 1" type="thunderbolt" />
                  <a-icon v-else type="user" />
                  {{ role.name }}
              </a-tag>
            </div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="editable-row-operations">
              <span>
                <a href="javascript:;" @click="() => showEditPermModal(record)" :disabled="record.id === 1"><a-icon type="edit" /> Edit</a>
              </span>
              <span>
              <a-popconfirm
                v-if="permData.length"
                title="Sure to delete?"
                @confirm="() => deletePerm(record.id)"
              >
                <a href="javascript:;"
                  :disabled="record.id === 1"
                  v-add-class-hover="'text-danger'"
                >
                  <a-icon type="delete" /> Delete
                </a>
              </a-popconfirm>
            </span>
            </div>
          </template>
        </a-table>
      </a-card>
    </div>
    <a-modal
      v-model="addRoleModal"
      title="Create Role"
      @ok="handleSubmitAddRole"
      :footer="null"
      class="add-role-modal"
      width="500px"
      :destroyOnClose="true"
    >
      <add-role-component
        @closeModalFunction="handleCloseAddRole"
        @submitModalFunction="handleSubmitAddRole"
      />
    </a-modal>
    <a-modal
      v-model="editRoleModal"
      title="Edit Role"
      @ok="handleSubmitEditRole"
      :footer="null"
      class="edit-role-modal"
      width="500px"
      :destroyOnClose="true"
    >
      <edit-role-component
        :role-info="selectedRole"
        @closeModalFunction="handleCloseEditRole"
        @submitModalFunction="handleSubmitEditRole"
      />
    </a-modal>
    <a-modal
      v-model="addPermModal"
      title="Create permission"
      @ok="handleSubmitAddPerm"
      :footer="null"
      class="add-perm-modal"
      width="500px"
      :destroyOnClose="true"
    >
      <add-perm-component
        @closeModalFunction="handleCloseAddPerm"
        @submitModalFunction="handleSubmitAddPerm"
      />
    </a-modal>
    <a-modal
      v-model="editPermModal"
      title="Edit permission"
      @ok="handleSubmitEditPerm"
      :footer="null"
      class="edit-perm-modal"
      width="500px"
      :destroyOnClose="true"
    >
      <edit-perm-component
        :perm-info="selectedPerm"
        @closeModalFunction="handleCloseEditPerm"
        @submitModalFunction="handleSubmitEditPerm"
      />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from 'store'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import addRoleComponent from '@/views/permissions/addRole.vue'
import editRoleComponent from '@/views/permissions/editRole.vue'
import addPermComponent from '@/views/permissions/addPerm.vue'
import editPermComponent from '@/views/permissions/editPerm.vue'

const roleColumns = [
  {
    title: 'Role name',
    dataIndex: 'name',
    width: '20%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Permissions',
    dataIndex: 'permissions',
    width: '60%',
    scopedSlots: { customRender: 'permissions' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]
const permColumns = [
  {
    title: 'Permission',
    dataIndex: 'name',
    width: '40%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Roles',
    dataIndex: 'roles',
    width: '40%',
    scopedSlots: { customRender: 'roles' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'permissions',
  mixins: [accessMix],
  components: { addRoleComponent, editRoleComponent, addPermComponent, editPermComponent },
  computed: {
    ...mapState(['user']),
    rolesData() {
      return this.roles
    },
    permData() {
      return this.permissions
    },
  },
  data() {
    return {
      roleColumns,
      permColumns,
      roles: store.get('app.users.roles'),
      permissions: store.get('app.users.permissions'),
      permNames: this.getPermissionsCategories(),
      addRoleModal: false,
      addPermModal: false,
      editRoleModal: false,
      editPermModal: false,
      selectedRole: undefined,
      selectedPerm: undefined,
      isHovering: false,
    }
  },
  created () {
    this.getPermissionTypesByName('settings')
  },
  mounted () {
    // console.log(this.permData)
  },
  methods: {
    showAddRoleModal() {
      this.addRoleModal = true
    },
    showEditRoleModal(obj) {
      this.selectedRole = obj
      this.editRoleModal = true
    },
    showAddPermModal() {
      this.addPermModal = true
    },
    showEditPermModal(obj) {
      this.selectedPerm = obj
      console.log(this.selectedPerm)
      this.editPermModal = true
    },
    handleCloseAddRole(e) {
      this.addRoleModal = false
    },
    handleCloseEditRole(e) {
      this.editRoleModal = false
    },
    handleCloseAddPerm(e) {
      this.addPermModal = false
    },
    handleCloseEditPerm(e) {
      this.editPermModal = false
    },
    handleSubmitAddRole(e) {
      this.addRoleModal = false
      this.roles.push(e)
      const roleObj = e
      const tPerms = this.permissions
      roleObj.permissions.forEach(function(perm) {
        // console.log(perm)
        const ind = tPerms.findIndex(x => x.name === perm.name)
        const newRole = { id: roleObj.id, name: e.name }
        if (ind >= 0) { tPerms[ind].roles.push(newRole) }
      })
      this.permissions = tPerms
      store.set('app.users.permissions', this.permissions)
    },
    handleSubmitAddPerm(e) {
      this.addPermModal = false
      this.permissions.push(e)
      const permObj = e
      const tRoles = this.roles
      permObj.roles.forEach(function(role) {
        // console.log(role)
        const ind = tRoles.findIndex(x => x.name === role.name)
        const newPerm = { id: permObj.id, name: e.name }
        if (ind >= 0) { tRoles[ind].permissions.push(newPerm) }
      })
      this.roles = tRoles
      store.set('app.users.roles', this.roles)
    },
    handleSubmitEditRole(e) {
      this.editRoleModal = false
      if (typeof e !== 'undefined') {
        const ind = this.roles.findIndex(x => x.id === e.id)
        if (ind >= 0) {
          this.roles[ind] = e
          store.set('app.users.roles', this.roles)
        }
        this.roleSyncPermissions(e.name, e.permissions)
        this.permissions = store.get('app.users.permissions')
      }
    },
    handleSubmitEditPerm(e) {
      this.editPermModal = false
      if (typeof e !== 'undefined') {
        const ind = this.permissions.findIndex(x => x.id === e.id)
        if (ind >= 0) {
          this.permissions[ind] = e
          store.set('app.users.permissions', this.permissions)
        }
        this.permissionSyncRoles(e.name, e.roles)
        this.roles = store.get('app.users.roles')
      }
    },
    deleteRole(roleID) {
      const url = 'admin/roles/' + roleID
      return apiClient.delete(url).then((response) => {
        const delRole = this.roles.find(obj => {
          return obj.id === roleID
        })
        const updatedRoles = this.getDifferenceArray(this.roles, [delRole])
        this.roles = updatedRoles
        store.set('app.users.roles', updatedRoles)
        this.removeRoleFromAllPermissions(delRole.name)
        this.permissions = store.get('app.users.permissions')
        this.$notification.success({
          message: 'Role "' + delRole.name + '" successfully deleted',
          description: '',
        })
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting role',
          description: error.message,
        })
      })
    },
    deletePerm(permID) {
      const url = 'admin/permissions/' + permID
      return apiClient.delete(url).then((response) => {
        const delPerm = this.permissions.find(obj => {
          return obj.id === permID
        })
        const updatedPerms = this.getDifferenceArray(this.permissions, [delPerm])
        this.permissions = updatedPerms
        store.set('app.users.permissions', updatedPerms)
        this.removePermissionFromAllRoles(delPerm.name)
        this.roles = store.get('app.users.roles')
        this.$notification.success({
          message: 'Permission "' + delPerm.name + '" successfully deleted',
          description: '',
        })
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting permission',
          description: error.message,
        })
      })
    },
  },

}
</script>
<style scoped>
  .editable-row-operations span { margin: 0 0.5rem 0 0.5rem }
</style>
